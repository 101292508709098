import React, { useEffect, useState } from "react";
import MyMDXFile from "../../content/how-to-find-desktop-pc-components.mdx";
import Header_Layout from "../../components/layouts/Header_Layout";
import { HeadFC, useStaticQuery } from "gatsby";
import TableOfContents from "../../components/manual/TableOfContents";
import { graphql } from "gatsby";
import {
  blog_page_styles,
  components,
} from "../../components/manual/customMdx";

const Blog1 = () => {
  const post_data = useStaticQuery(graphql`
    query Blog1TOC {
      mdx(frontmatter: { slug: { eq: "how-to-find-desktop-pc-components" } }) {
        tableOfContents
      }
    }
  `);

  const [page_data, set_page_data] = useState<any | undefined>();

  useEffect(() => {
    set_page_data(post_data.mdx.tableOfContents);
  }, [post_data]);

  return (
    <Header_Layout>
      <main style={blog_page_styles}>
        {page_data && <TableOfContents items={page_data.items} />}
        <MyMDXFile components={components} />
      </main>
    </Header_Layout>
  );
};

export default Blog1;

export const Head: HeadFC = () => (
  <title>How to Find Your Desktop Components - BitLot.app</title>
);
