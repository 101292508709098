import React from "react";

function getAnchor(text: string) {
  console.log(text);
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^a-z0-9\-]/g, "");
}

const H2 = ({ children }: { children?: any }) => {
  const anchor = getAnchor(children);
  const link = `#${anchor}`;
  return (
    <h2 id={anchor}>
      <a href={link} style={{ display: "none" }} className="anchor-link">
        §
      </a>
      {children}
    </h2>
  );
};

export default H2;
