import React, { useEffect, useState } from "react";

type Item = {
  url: string;
  title: string;
  shortTitle?: string;
  items: Item[];
};

type Props = {
  items: Item[];
};

const table_of_contents_style = {
  position: "fixed" as "fixed",
  zIndex: 99,
  border: "1px solid black",
  padding: "1rem",
  width: "14rem",
  fontSize: 16,
  textDecoration: "none",
  background: "white",
  top: 80,
  right: 16,
  maxHeight: 300,
  overflowY: "scroll" as "scroll",
};

function getIds(items: Item[]) {
  return items.reduce((acc: string[], item: Item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1));
    }
    if (item.items) {
      acc.push(...getIds(item.items));
    }
    return acc;
  }, []);
}

function useActiveId(itemIds: string[]) {
  const [activeId, setActiveId] = useState(``);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` }
    );
    itemIds.forEach((id) => {
      const el = document.getElementById(id);
      if (el) {
        observer.observe(el);
      }
    });
    return () => {
      itemIds.forEach((id) => {
        const el = document.getElementById(id);
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, [itemIds]);
  return activeId;
}

function renderItems(items: Item[], activeId: string) {
  return (
    <ul style={{ paddingLeft: "12px" }}>
      {items.map((item) => {
        return (
          <li key={item.url}>
            <a
              href={item.url}
              style={{
                color: activeId === item.url.slice(1) ? "tomato" : "green",
                textDecoration: "none",
                fontSize: "0.7rem",
              }}
            >
              {item.shortTitle ?? item.title}
            </a>
            {item.items && renderItems(item.items, activeId)}
          </li>
        );
      })}
    </ul>
  );
}

function TableOfContents(props: Props) {
  const idList = getIds(props.items);
  const activeId = useActiveId(idList);
  return (
    <main style={table_of_contents_style}>
      <details open>
        <summary style={{ fontSize: "1rem" }}>Table of Contents</summary>
        {renderItems(props.items, activeId)}
      </details>
    </main>
  );
}

export default TableOfContents;
